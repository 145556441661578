<template>
  <div class="container mt-4 mb-4">
    <b-card v-if="plans" no-body class="card-company-table shadow">
      <b-card class="text-center" v-if="planList && planList.length == 0"
        >No record found</b-card
      >
      <b-table
        v-else
        :items="planList"
        responsive
        :fields="fields"
        class="mb-0"
      >
        <!-- company -->
        <template #cell(symbol)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar
              size="35"
              class="mr-3"
              variant="info"
              src="https://placekitten.com/300/300"
            ></b-avatar> -->
            <div>
              <div class="font-weight-bolder">
                {{ data.item.symbol }}
              </div>
              <!-- <div class="font-small-2 text-muted">
                {{ data.item.subtitle }}
              </div> -->
            </div>
          </div>
        </template>

        <template #cell(total_amount)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar class="mr-3" variant="success">
              <feather-icon size="18" :icon="data.item.avatarIcon" />
            </b-avatar> -->
            <span>{{ data.item.total_amount }}</span>
          </div>
        </template>

        <template #cell(available_amount)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar class="mr-3" variant="success">
              <feather-icon size="18" :icon="data.item.avatarIcon" />
            </b-avatar> -->
            <span>{{ data.item.available_amount }}</span>
          </div>
        </template>

        <!-- category -->
        <template #cell(returnable_amount)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar class="mr-3" variant="success">
              <feather-icon size="18" :icon="data.item.avatarIcon" />
            </b-avatar> -->
            <span>{{ data.item.returnable_amount }}</span>
          </div>
        </template>

        <template #cell(pnl)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar class="mr-3" variant="success">
              <feather-icon size="18" :icon="data.item.avatarIcon" />
            </b-avatar> -->
            <span>{{ data.item.pnl_per }}%</span>
          </div> </template
        >\

        <template #cell(int_rate)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar class="mr-3" variant="success">
              <feather-icon size="18" :icon="data.item.avatarIcon" />
            </b-avatar> -->
            <span>{{ data.item.int_rate }}%</span>
          </div>
        </template>

        <template #cell(duration)="data">
          <div class="d-flex align-items-center">
            <!-- <b-avatar class="mr-3" variant="success">
              <feather-icon size="18" :icon="data.item.avatarIcon" />
            </b-avatar> -->
            <span>{{ data.item.duration }}</span>
          </div>
        </template>

        <!-- views -->
        <!-- <template #cell(duration)="data">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder mb-25">{{
              data.item.viewTitle
            }}</span>
            <span class="font-small-2 text-muted text-nowrap">{{
              data.item.viewsub
            }}</span>
          </div>
        </template> -->

        <!-- revenue -->
        <!-- <template #cell(pnl)="data">
          {{ "$" + data.item.revenue }}
        </template> -->

        <!-- sales -->
        <!-- <template #cell(projection)="data">
          <div class="d-flex align-items-center">
            <span class="font-weight-bolder mr-1">{{
              data.item.sales + "%"
            }}</span>
            <feather-icon
            :icon="data.item.loss ? 'TrendingDownIcon' : 'TrendingUpIcon'"
            :class="data.item.loss ? 'text-danger' : 'text-success'"
          />
          </div>
        </template> -->
        <template #cell(action)>
          <b-button variant="outline-primary" @click="$router.push('/invest/1')"
            >Invest Now</b-button
          >
        </template>
      </b-table>
    </b-card>
    <loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { BCard, BTable, BButton } from "bootstrap-vue";
import Loader from "../components/Loader.vue";
import { mapState } from "vuex";
// import AvailableAmount from "../components/AvailableAmount.vue";
// import InvestedAmount from "../components/InvestedAmount.vue";
// import PnL from "../components/PnL.vue";

export default {
  computed: {
    ...mapState(["planList"]),
  },
  components: {
    BCard,
    BTable,
    // BAvatar,

    BButton,
    Loader,
    // AvailableAmount,
    // InvestedAmount,

    // PnL, // BImg,
  },

  data() {
    return {
      isLoading: false,
      plans: [
        {
          duration: 90,
          int_rate: 10,
          total_amount: 10000,
          symbol: "USDT",
          available_amount: 2000,
          returnable_amount: 2200,
          pnl_per: 9,
          source: { id: 1, name: "Bitbns" },
        },
      ],
      fields: [
        { key: "symbol", label: "SYMBOL" },
        { key: "total_amount", label: "TOTAL AMOUNT" },
        { key: "available_amount", label: "AVAILABLE AMOUNT" },
        { key: "returnable_amount", label: "RETURNABLE AMOUNT" },
        { key: "pnl", label: "PNL" },
        { key: "int_rate", label: "Interest Rate" },
        { key: "duration", label: "Duration" },
        { key: "action", label: "" },
      ],
      // tableData: [
      //   {
      //     title: "Karan",
      //     subtitle: "verma@gmail.com",
      //     viewTitle: "24k",
      //     viewsub: "in 24 hr",
      //     revenue: "862",
      //     sales: "12",
      //     avatarTitle: "Technology",
      //   },
      //   {
      //     title: "Karan",
      //     subtitle: "verma@gmail.com",
      //     viewTitle: "24k",
      //     viewsub: "in 24 hr",
      //     revenue: "862",
      //     sales: "12",
      //     avatarTitle: "Technology",
      //   },
      //   {
      //     title: "Karan",
      //     subtitle: "verma@gmail.com",
      //     viewTitle: "24k",
      //     viewsub: "in 24 hr",
      //     revenue: "862",
      //     sales: "12",
      //     avatarTitle: "Technology",
      //   },
      // ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fetchPlanOrSourceList");
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
// @import '~@core/scss/base/bootstrap-extended/include';
// @import '~@core/scss/base/components/variables-dark';

// .card-company-table ::v-deep td .b-avatar.badge-light-company {
//   .dark-layout & {
//     background: $theme-dark-body-bg !important;
//   }
// }
.font-small-2 {
  font-size: 13px;
}
div {
  white-space: nowrap;
}
</style>
